import React from "react";
import Container from "../container/container";
import { SearchByTire } from "../searchByTire/searchByTire";
import carPhotoDesktop from "../../images/tiresDesktop.webp";
import carPhotoMobile from "../../images/tiresDesktop.webp";

import * as StyleComponents from "./tirePage.module.scss";

// markup
export const TiresPageComponent = () => {
  return (
    <Container>
      <picture>
        <source
          media="(max-width: 1200px)"
          type="image/jpeg"
          srcSet={carPhotoMobile}
        />
        <img
          src={carPhotoDesktop}
          alt="Jeep 4x4 canada offroad with big tires"
          className={StyleComponents.mainImage}
        />
      </picture>
      <SearchByTire />
    </Container>
  );
};

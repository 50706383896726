import * as React from "react";
import { PageLayout } from "../components/layout/layout";
import { TiresPageComponent } from "../components/tirePage/tirePage";

// markup
const TiresPage = () => {
  return (
    <PageLayout>
      <TiresPageComponent />
    </PageLayout>
  );
};

export default TiresPage;
